import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import _ from 'lodash';
import { DefaultGlobalConfig, GlobalConfig, ToastrService } from 'ngx-toastr';
import { HeadLinePracticeAreaResponse } from 'src/app/shared/interfaces/Admin/Role/HeadLinePracticeAreaNyRoleIdRespomse';
import {
  ListOfSubPracticeArea,
  SubPracticeArea,
  SubPracticeAreaByHeadlineIdResponse,
} from 'src/app/shared/interfaces/Admin/Role/SubPracticeAreaByHeadlineIdResponse';
import { AddUserRequest, PracticeArea } from 'src/app/shared/interfaces/Admin/User/AddUserRequest';
import { User, UserDetailsByUserIdResponse } from 'src/app/shared/interfaces/Auth/UserDetailsByUserIdResponse';
import { InternalNavigationState } from 'src/app/shared/interfaces/NavigationState';
import { AuthService, LoginService, RoleService } from 'src/app/shared/services';

export interface Person {
  name: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  @ViewChild('profilephotoElement') profilephotoElement: ElementRef;

  isAdmin: boolean = false;
  currentUserState: {
    isUserLoggingIn: boolean;
    isNewUser: boolean;
    isNewPwdSet: boolean;
    memberTypeName: string;
    userId: number;
    roleTypeName: string;
    roleId: number;
    userName: string;
    roleName: string;
  } = {
    isUserLoggingIn: true,
    isNewPwdSet: true,
    isNewUser: true,
    memberTypeName: '',
    roleId: -1,
    roleTypeName: '',
    userId: -1,
    userName: '',
    roleName: '',
  };
  currentUserAccessToken: string | null;
  isProfileCompleteButtonClicked: boolean = false;
  isProfilePhotoUploaded: boolean = false;

  completeProfileForm: UntypedFormGroup = new UntypedFormGroup({
    fullNameControl: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]{2,25}$')]),
    roleControl: new UntypedFormControl(''),
  });
  headLinePracticeArea: any[];
  subPracticeArea: any[];
  listOfSubPracticeArea: ListOfSubPracticeArea[];

  headLinePracticeSelectedValue: number[] = [];
  subPracticeSelectedValue: number[] = [];

  fileList: FileList;
  fileNameText: string | null;
  previewImage: string | ArrayBuffer | null;
  user: User;

  constructor(
    private _loginService: LoginService,
    private router: Router,
    private toastrService: ToastrService,
    private _roleService: RoleService,
    private _authService: AuthService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as InternalNavigationState;
    if (!state) {
      this.navigateToLogin(true);
    } else {
      this.currentUserState.isUserLoggingIn = state.isUserLoggingIn ?? false;
      this.currentUserState.isNewUser = state.isNewUser ?? false;
      this.currentUserState.memberTypeName = state.memberTypeName ?? '';
      this.currentUserState.isNewPwdSet = state.isNewPwdSet ?? false;
      this.currentUserState.userId = state.userId ?? 0;
      this.currentUserState.roleTypeName = state.roleTypeName ?? '';
      if (this.currentUserState.roleTypeName == 'Administrative') this.isAdmin = true;
      this.currentUserState.roleId = state.roleId ?? 0;
      this.currentUserState.userName = state.userName ?? '';
      this.currentUserState.roleName = state.roleName ?? '';
      if (this._authService.get('accessToken')) this.currentUserAccessToken = this._authService.get('accessToken');
      this.getUserById();
    }
  }

  navigateToLogin(replaceUrl: boolean = false) {
    this.router.navigate(['login'], {
      replaceUrl: replaceUrl,
    });
  }

  getUserById() {
    this._loginService.getUserByID(this.currentUserState.userId).subscribe((res: UserDetailsByUserIdResponse) => {
      this.user = res.result;
      this.completeProfileForm.controls.fullNameControl.setValue(this.user.fullName);
      this.completeProfileForm.controls.roleControl.setValue(this.user.roleName);
      this.completeProfileForm.controls.roleControl.disable();
      this._loginService.setPracticeAreaData(res.result.practiceArea);
      if (res.result.practiceArea && !this.isAdmin) {
        this.getHeadLinePracticeAreaByRoleId();
        this.headLinePracticeSelectedValue = res.result.practiceArea.map((pa) => pa.headlinePracticeAreaId);
        this.getSubPracticeAreaByHeadLineId(this.headLinePracticeSelectedValue);
        this.subPracticeSelectedValue = _.flatten(
          res.result.practiceArea.map((pa) => {
            if (pa.subPracticeAreaId) return _.flatten(pa.subPracticeAreaId.map((sub) => sub.subPracticeAreaId));
            else return [];
          }),
        );
      } else {
        this.headLinePracticeSelectedValue = [];
        this.subPracticeSelectedValue = [];
      }
    });
  }

  getHeadLinePracticeAreaByRoleId() {
    this._roleService.getHeadlinePracticeArea().subscribe((res: HeadLinePracticeAreaResponse) => {
      this.headLinePracticeArea = res.result;
    });
  }

  getSubPracticeAreaByHeadLineId(ids: number[]) {
    this._roleService.getSubPracticeAreaByHPAId(ids).subscribe((res: SubPracticeAreaByHeadlineIdResponse) => {
      this.listOfSubPracticeArea = res.result;
      this.subPracticeArea = _.flatten(
        res.result.map((item) => {
          return item.subPracticeAreaResponseModel;
        }),
      );
    });
  }

  // upload file
  addFile(event: Event) {
    this.isProfilePhotoUploaded = true;
    const element = event.currentTarget as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      if (element.files[0].size > 5 * 2 ** 20) {
        this.isProfilePhotoUploaded = false;
        const globalConfig: GlobalConfig = DefaultGlobalConfig;
        globalConfig.preventDuplicates = true;
        this.toastrService.toastrConfig = globalConfig;
        this.toastrService.error('Profile image cannot be exceeding 5 MB');
      } else {
        this.fileList = element.files;
        this.fileNameText = this.fileList[0].name;
        const globalConfig: GlobalConfig = DefaultGlobalConfig;
        globalConfig.preventDuplicates = true;
        this.toastrService.toastrConfig = globalConfig;
        this._loginService.uploadProfilePhoto(this.fileList[0]).subscribe({
          next: () => {
            this.toastrService.success('Successfully Uploaded Profile Image');
            const reader = new FileReader();
            reader.onload = () => (this.previewImage = reader.result);
            reader.readAsDataURL(this.fileList[0]);
            this.isProfilePhotoUploaded = false;
          },
          error: () => {
            this.toastrService.error('Profile Image could not be uploaded');
            this.isProfilePhotoUploaded = false;
          },
        });
      }
    } else {
      this.isProfilePhotoUploaded = false;
      const globalConfig: GlobalConfig = DefaultGlobalConfig;
      globalConfig.preventDuplicates = true;
      this.toastrService.toastrConfig = globalConfig;
      this.toastrService.error('No Profile Image Selected');
    }
  }

  submitForm() {
    this.isProfileCompleteButtonClicked = true;
    if (this.completeProfileForm.valid) {
      let practiceArea: PracticeArea[];
      if (this.headLinePracticeSelectedValue.length > 0) {
        practiceArea = this.headLinePracticeSelectedValue.map((hpa) => {
          const temp: SubPracticeArea[] = this.listOfSubPracticeArea.filter((spal) => {
            return spal.headlineId === hpa;
          })[0].subPracticeAreaResponseModel;
          let tempids: number[] = [];
          if (temp) tempids = temp.map((tmid) => tmid.subPracticeAreaId);
          let tempspa: number[] = [];
          if (temp && this.subPracticeSelectedValue) {
            tempspa = _.intersection(this.subPracticeSelectedValue, tempids);
          }
          return { headlinePracticeAreaId: hpa, subPracticeAreaId: tempspa };
        });
      } else {
        practiceArea = [];
      }

      const addUserReq: AddUserRequest = {
        userId: this.currentUserState.userId,
        roleTypeId: this.user.roleTypeId,
        roleId: this.user.roleId,
        userName: this.completeProfileForm.controls.fullNameControl.value,
        practiceArea: practiceArea,
      };

      this._loginService.completeProfile(addUserReq).subscribe({
        next: () => {
          this._authService.remove('accessToken');
          this.isProfileCompleteButtonClicked = false;
          this.currentUserState.isNewUser = false;
          const state: any = this.currentUserState;
          if (this.currentUserAccessToken) this._authService.set('accessToken', this.currentUserAccessToken, state);
          this.router.navigate(['/admin'], {
            replaceUrl: true,
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isProfileCompleteButtonClicked = false;
          const globalConfig: GlobalConfig = DefaultGlobalConfig;
          this.toastrService.toastrConfig = { ...globalConfig, preventDuplicates: true };
          this.toastrService.error(error.error.message);
        },
      });
    } else {
      this.isProfileCompleteButtonClicked = false;
      if (this.completeProfileForm.controls.fullNameControl.hasError('required')) {
        this.completeProfileForm.controls.fullNameControl.markAsTouched();
        this.completeProfileForm.controls.fullNameControl.markAsDirty();
        this.completeProfileForm.controls.fullNameControl.setValue('');
      } else if (this.completeProfileForm.controls.fullNameControl.hasError('pattern')) {
        this.completeProfileForm.controls.fullNameControl.markAsTouched();
        this.completeProfileForm.controls.fullNameControl.markAsDirty();
      }
    }
  }
}
