import { Configuration } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.config.auth.clientId,
    authority: environment.config.auth.authority,
    redirectUri: environment.config.auth.redirectUri,
    postLogoutRedirectUri: environment.config.auth.postLogoutRedirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: environment.config.cache.cacheLocation,
    storeAuthStateInCookie: isIE,
  },
};

export const protectedResources = {
  demoApi: {
    endpoint: environment.config.resources.demoApi.resourceUri,
    scopes: [environment.config.resources.demoApi.resourceScope],
  },
};

export const loginRequest = {
  scopes: [...environment.config.scopes.loginRequest],
};
