import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

try {
  const channel = new BroadcastChannel('app-data');
  let runningTimer;
  channel.addEventListener('message', (event) => {
    if (event.data && event.data != '') {
      const seconds = event.data;
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.round(seconds % 60);
      const hourString = h > 9 ? h : '0' + h;
      const hoursMinuteString = h ? '0' + m : '0' + m || '00';
      const minuteString = m > 9 ? m : hoursMinuteString;
      const secondString = s > 9 ? s : '0' + s;
      runningTimer = [hourString, minuteString, secondString].filter(Boolean).join(':');
      document.title = runningTimer;
    } else {
      document.title = 'Harper James';
    }
  });
} catch (error) {
  console.error('Broadcast channel is not supported!');
}

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
    window.console.info = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
