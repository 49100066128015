import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private router = inject(Router);
  private angularPlugin = new AngularPlugin();
  private appInsights: ApplicationInsights;
  private resize: ResizeObserver;
  private windowResize = new BehaviorSubject<DOMRectReadOnly>(document.body.getBoundingClientRect());

  constructor() {
    if (environment.appInsightsConnectionString == '') return;

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsightsConnectionString,
        extensions: [this.angularPlugin],
        extensionConfig: {
          [this.angularPlugin.identifier]: { router: this.router },
        },
      },
    });

    this.appInsights.loadAppInsights();
    this.appInsights.context.application.ver = environment.appVersion;

    this.resize = new ResizeObserver((entries) => {
      this.windowResize.next(entries[0].contentRect);
    });

    this.resize.observe(document.body);

    this.windowResize.pipe(debounceTime(10000)).subscribe((data) => {
      this.trackEvent('Window Resized', { screensize: `${Math.round(data.width)}x${Math.round(data.height)}` });
    });
  }

  trackEvent(name: string, properties?: { [key: string]: string }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name, properties });
    }
  }
}
