<div class="auth-wrap">
  <div class="auth-wrap-left">
    <div class="logo">
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="auth-wrap-left-content">
      <h1>Let’s get going!</h1>
      <img src="../../assets/images/login-set.png" alt="" />
    </div>
  </div>
  <div class="auth-wrap-right">
    <div class="auth-wrap-right-content profile-page-right-content">
      <h1 class="margin-0">Complete your profile</h1>
      <h3>Enter your details to proceed further</h3>
      <div class="edit-profile">
        <img
          *ngIf="previewImage; else defaultAvatar"
          [src]="previewImage || '../../assets/images/person-circle.svg'"
          alt=""
        />
        <ng-template #defaultAvatar>
          <img class="default-user-avatar" src="../../assets/images/person-circle.svg" alt="" />
        </ng-template>
        <div class="edit-profile-input" [ngClass]="{ 'default-user-avatar-edit-img': !previewImage }">
          <input
            type="file"
            id="file-input"
            class="file"
            (change)="addFile($event)"
            accept=".jpg,.png,.jpeg"
            [disabled]="isProfilePhotoUploaded"
            #profilephotoElement
          />
          <span class="edit-profile-input-icon"></span>
        </div>
      </div>
      <div class="auth-form">
        <form [formGroup]="completeProfileForm">
          <div class="form-field">
            <label for="fullName">Full Name</label>
            <input
              id="fullName"
              matInput
              type="text"
              formControlName="fullNameControl"
              placeholder="Enter Full Name"
              trim="blur"
            />
            <mat-error
              *ngIf="
                completeProfileForm.controls.fullNameControl.hasError('required') &&
                completeProfileForm.controls.fullNameControl.touched
              "
            >
              Full Name can not be blank
            </mat-error>
            <mat-error *ngIf="completeProfileForm.controls.fullNameControl.hasError('pattern')">
              Invalid Full Name
            </mat-error>
            <svg-icon name="user-icon" style="color: #8f9bba" class="input-icon"></svg-icon>
          </div>
          <div class="form-field">
            <label for="role">Role</label>
            <input
              id="role"
              matInput
              type="text"
              formControlName="roleControl"
              placeholder="Enter Role"
              name="role"
              trim="blur"
            />
            <svg-icon name="userRole-icon" class="input-icon"></svg-icon>
          </div>
        </form>

        <div class="row" *ngIf="!isAdmin">
          <div class="col-6">
            <div class="form-field form-field-select">
              <label for="headlinePracticeArea">Headline Practice Area</label>
              <div>
                <ng-select
                  labelForId="headlinePracticeArea"
                  [items]="headLinePracticeArea"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [clearSearchOnAdd]="true"
                  [searchable]="false"
                  bindLabel="headlinePracticeAreaName"
                  bindValue="id"
                  [(ngModel)]="headLinePracticeSelectedValue"
                  [disabled]="true"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-field form-field-select">
              <label for="subPracticeArea">Sub-Practice Area</label>
              <div class="form-field-dropdown">
                <ng-select
                  labelForId="subPracticeArea"
                  [items]="subPracticeArea"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [clearSearchOnAdd]="true"
                  [searchable]="false"
                  bindLabel="subPracticeAreaName"
                  bindValue="subPracticeAreaId"
                  [(ngModel)]="subPracticeSelectedValue"
                  [disabled]="true"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-lg" [disabled]="isProfileCompleteButtonClicked" (click)="submitForm()">
          Save & Continue
        </button>
      </div>
    </div>
  </div>
</div>
