import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/shared/constants/constants';
import { ValidationMessages } from 'src/app/shared/enums/messages';
import { LoginService } from '../../../shared/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  hide: boolean = true;
  hideConfirm: boolean = true;
  resetPasswordform: UntypedFormGroup;
  currentUserId: number = -1;
  isPasswordReset: boolean = false;

  validationMessages = ValidationMessages;

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private toastrService: ToastrService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        isUserLoggingIn: boolean;
        isNewUser: boolean;
        isNewPwdSet: boolean;
        accessToken: string;
        userId: number;
      };
      if (state) {
        this.currentUserId = state.userId;
      } else {
        this.router.navigate(['login'], {
          replaceUrl: true,
        });
      }
    } else {
      this.router.navigate(['login'], {
        replaceUrl: true,
      });
    }

    this.resetPasswordform = new UntypedFormGroup(
      {
        passwordControl: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(Config.validPasswordRegexAcceptingSpecialChars),
        ]),
        confirmPasswordControl: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(Config.validPasswordRegexAcceptingSpecialChars),
        ]),
      },
      this.passwordMatchValidator,
    );
  }

  hideViewPassword(isConfirm: boolean) {
    if (!isConfirm) this.hide = !this.hide;
    else this.hideConfirm = !this.hideConfirm;
  }

  resetPassword() {
    this.isPasswordReset = true;
    if (!this.resetPasswordform.valid) {
      this.isPasswordReset = false;
      this.validatePasswords();
      return;
    }
    this._loginService
      .resetPassword(
        this._loginService.encryptPassword(this.resetPasswordform.controls.passwordControl.value),
        this._loginService.encryptPassword(this.resetPasswordform.controls.confirmPasswordControl.value),
        this.currentUserId,
      )
      .subscribe({
        next: (res) => {
          this.isPasswordReset = false;
          this.toastrService.success(res.message);
          localStorage.clear();
          this.router.navigate(['login'], {
            replaceUrl: true,
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isPasswordReset = false;
          this.toastrService.toastrConfig = { ...DefaultGlobalConfig, preventDuplicates: true };
          this.toastrService.error(error.error.message);
        },
      });
  }

  markFormControlAsDirty(formControlName: string) {
    this.resetPasswordform.get(formControlName)?.markAsDirty();
    this.resetPasswordform.get(formControlName)?.markAsTouched();
  }

  validatePasswords() {
    if (this.resetPasswordform.get('passwordControl')?.hasError('required')) {
      this.markFormControlAsDirty('passwordControl');
      this.resetPasswordform.get('passwordControl')?.setValue('');
    } else if (this.resetPasswordform.get('passwordControl')?.hasError('pattern')) {
      this.markFormControlAsDirty('passwordControl');
    } else if (this.resetPasswordform.get('confirmPasswordControl')?.hasError('required')) {
      this.markFormControlAsDirty('confirmPasswordControl');
      this.resetPasswordform.get('confirmPasswordControl')?.setValue('');
    } else if (this.resetPasswordform.get('confirmPasswordControl')?.hasError('pattern')) {
      this.markFormControlAsDirty('confirmPasswordControl');
    }
  }

  passwordMatchValidator(resetPasswordform: AbstractControl) {
    return resetPasswordform.get('passwordControl')?.value === resetPasswordform.get('confirmPasswordControl')?.value
      ? null
      : { mismatch: true };
  }

  checkPasswordLength(event: KeyboardEvent, formControlName: string) {
    if (this.resetPasswordform?.get(formControlName)?.value.length >= 16) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
