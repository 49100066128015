import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MarkdownModule } from 'ngx-markdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationModule } from './admin/authentication/authentication.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientHeaderComponent } from './client/client-dashboard/client-header/client-header.component';
import { ClientSidemenuComponent } from './client/client-dashboard/client-sidemenu/client-sidemenu.component';
import { ClientDashboardComponent } from './client/client-dashboard/dashboard/client-dashboard.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { loginRequest, msalConfig, protectedResources } from './shared/constants/ms-sso-auth-config';
import { AppInitService } from './shared/services/app-init.service';
import { SharedModule } from './shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@NgModule({
  declarations: [AppComponent, ClientSidemenuComponent, ClientHeaderComponent, ClientDashboardComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AuthenticationModule,
    SharedModule,
    MatTooltipModule,
    MatMenuModule,
    NoopAnimationsModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      messageClass: 'toastr-message',
    }),
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: [...protectedResources.demoApi.scopes],
        },
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map([
          [protectedResources.demoApi.endpoint, [...loginRequest.scopes]], // Replace with your API URL
        ]),
      },
    ),
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () => {
        appInitService.initialize();
      },
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DecimalPipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    MsalService,
    provideCharts(withDefaultRegisterables()),
    importProvidersFrom(NgxSkeletonLoaderModule.forRoot({ animation: 'progress' })),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
