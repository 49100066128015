<div class="maintenance-wrap">
  <div class="maintenance-wrap-right">
    <div class="logo">
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="maintenance-wrap-right-content">
      <h1>We will be back soon!</h1>
      <h6>
        Sorry for the inconvenience. The system is under maintenance at the moment, the system will be back online
        shortly.
      </h6>
      <div class="from-team">- The Team</div>
    </div>
  </div>
</div>
