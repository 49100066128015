import { Component } from '@angular/core';
import { AuthService, LoginService, RoleService } from 'src/app/shared/services';
import { TimeLogService } from 'src/app/shared/services/time-log.service';
import { TimesheetService } from 'src/app/shared/services/timesheet.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent {
  constructor(
    private _authService: AuthService,
    private _timeLogService: TimeLogService,
    private _loginService: LoginService,
    private _roleservice: RoleService,
    private _timesheetService: TimesheetService,
  ) {
    this._loginService.setUpdatedData('userName', '');
    this._loginService.setUpdatedData('userProfile', '');
    this._roleservice.setUpdatedData([]);
    this._timesheetService.setUpdatedData(-1);
    this._timeLogService.endTimerGlobally();
    this._authService.logout();
  }
}
