import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  private onlineSubject = new BehaviorSubject<boolean>(true);
  onlineObservable: Observable<boolean> = this.onlineSubject.asObservable();

  constructor() {}

  setOnline(data: boolean) {
    this.onlineSubject.next(data);
  }
}
