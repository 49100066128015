<ng-template #emailConfirmed let-modal>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <div class="modal-body">
    <h2>Email-ID has been successfully confirmed</h2>
    <p>
      We’ve noticed that the confirmation link sent to your email id <b>{{ email }}</b> to update your email address has
      been confirmed. You can proceed with the login now and continue.
    </p>
    <div class="successfully-foot-btn max-w-341">
      <button class="btn btn-block btn-md" data-dismiss="modal" (click)="openLogin()">Login</button>
    </div>
  </div>
</ng-template>
<ng-template #linkExpired let-modal>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <div class="modal-body">
    <h2 class="text-red">Confirmation Link Expired</h2>
    <p>
      We’ve noticed that the confirmation link sent to your email id <b>{{ email }}</b> to update your email address was
      not confirmed within 72 hours. Please contact the administrator to update your email-id.
    </p>
    <div class="successfully-foot-btn max-w-341">
      <button class="btn btn-block btn-md" data-dismiss="modal" (click)="modal.dismiss()">Ok</button>
    </div>
  </div>
</ng-template>

<div class="loader-animation" *ngIf="isScreenLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
