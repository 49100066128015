import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResponseCodes } from 'src/app/shared/enums/enums';
import { OTPResponse } from 'src/app/shared/interfaces/Auth/OTPResponse';
import { AuthService } from 'src/app/shared/services';
import { SingleSignOnService } from 'src/app/shared/services/single-sign-on.service';

@Component({
  selector: 'app-single-sign-on',
  templateUrl: './single-sign-on.component.html',
  styleUrl: './single-sign-on.component.scss',
})
export class SingleSignOnComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  isDataLoading: boolean = false;

  constructor(
    private _ssoService: SingleSignOnService,
    private _authService: AuthService,
    private _router: Router,
    private _toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.subscribeMsLoggedInUserDetails();
  }

  subscribeMsLoggedInUserDetails() {
    this._ssoService.msLoggedInUserDetails.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((userDetails) => {
      if (userDetails?.isLoggedIn && userDetails?.accessToken) {
        this.validateMsLoggedInUser(userDetails?.accessToken ?? '');
      }
    });
  }

  login() {
    this._ssoService.msLogin();
  }

  logout() {
    this._ssoService.msLogout();
  }

  validateMsLoggedInUser(token: string) {
    if (!token) {
      return;
    }
    this.manageLoader();
    this._authService.validateMsLoggedInUser(token).subscribe({
      next: this.handleValidateTokenSuccess.bind(this),
      error: this.handleValidateTokenFailure.bind(this),
    });
  }

  handleValidateTokenSuccess(result: OTPResponse) {
    const userDetails = result.result;
    localStorage.setItem('isRM', 'true');
    localStorage.setItem('isClient', 'false');
    this._authService.set('accessToken', userDetails.accessToken, {
      isUserLoggingIn: true,
      isNewUser: userDetails.isNewUser,
      isNewPwdSet: userDetails.isNewPwdSet,
      userId: userDetails.userId,
      roleTypeName: userDetails.roleTypeName,
      memberTypeName: userDetails.memberTypeName,
      roleId: userDetails.roleId,
      userName: userDetails.userName,
      roleName: userDetails.roleName,
    });
    this.manageLoader(false);
    this._authService.set('loggedInAt', new Date().toISOString());
    this._authService.set('isStatusSet', 'false');
    this._router.navigate(['admin'], { replaceUrl: true });
  }

  handleValidateTokenFailure(result: HttpErrorResponse) {
    if (result?.error?.statusCode === ResponseCodes.BadRequest) {
      this._toastr.error(result?.error?.message);
    }
    this.manageLoader(false);
  }

  manageLoader(isLoading: boolean = true) {
    this.isDataLoading = isLoading;
  }
}
