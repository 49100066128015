import { Injectable, OnDestroy } from '@angular/core';
import { Subject, of, throwError, timer } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService implements OnDestroy {
  intervalTime: number = 30000;
  onDestroy$: Subject<void> = new Subject();

  constructor(private userService: UserService) {}

  initialize() {
    this.userService.checkServerAvailability().subscribe();
    this.checkServerAvailability();
  }

  checkServerAvailability() {
    timer(30000, this.intervalTime)
      .pipe(
        switchMap(() => {
          return this.userService.checkServerAvailability().pipe(
            catchError(() => {
              return of(undefined);
            }),
          );
        }),
        filter((data) => data !== undefined),
        takeUntil(this.onDestroy$),
        catchError((error) => {
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
