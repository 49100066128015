import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SingleSignOnComponent } from './single-sign-on/single-sign-on.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ProfileComponent,
    LogoutComponent,
    ConfirmEmailComponent,
    MaintenanceComponent,
    SingleSignOnComponent,
  ],
  imports: [RouterModule, CommonModule, SharedModule, AngularSvgIconModule.forRoot()],
})
export class AuthenticationModule {}
