<div class="auth-wrap">
  <div class="auth-wrap-left">
    <div class="logo">
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="auth-wrap-left-content">
      <h1>Let’s get going!</h1>
      <img src="../../assets/images/login-set.png" alt="" />
    </div>
  </div>
  <div class="auth-wrap-right">
    <div class="auth-wrap-right-content">
      <h1>Welcome to Harper James Solicitors</h1>
      <h2>Sign In to proceed further</h2>
      <div class="auth-form">
        <form [formGroup]="loginForm">
          <div class="form-field">
            <label for="email">Email</label>
            <input
              id="email"
              matInput
              type="text"
              formControlName="emailControl"
              placeholder="Enter Email"
              name="email"
              (keydown.space)="$event.preventDefault()"
              #email
            />
            <mat-error
              *ngIf="loginForm.controls.emailControl.hasError('required') && loginForm.controls.emailControl.touched"
            >
              Email cannot be blank
            </mat-error>
            <mat-error
              *ngIf="
                loginForm.controls.emailControl.hasError('pattern') &&
                loginForm.controls.emailControl.dirty &&
                loginForm.controls.emailControl.touched
              "
            >
              Invalid email address
            </mat-error>
            <svg-icon name="mail-icon" style="color: #5f636b" class="input-icon"></svg-icon>
          </div>
          <div class="form-field">
            <label for="password">Password</label>
            <input
              id="password"
              matInput
              type="password"
              formControlName="passwordControl"
              placeholder="Enter Password"
              #password
              [type]="hide ? 'password' : 'text'"
              (keydown.space)="$event.preventDefault()"
              (keypress)="checkPasswordLength($event)"
            />
            <mat-error
              *ngIf="
                loginForm.controls.passwordControl.hasError('required') &&
                loginForm.controls.passwordControl.dirty &&
                loginForm.controls.passwordControl.touched
              "
            >
              Password can not be blank
            </mat-error>
            <mat-error
              *ngIf="
                loginForm.controls.passwordControl.hasError('pattern') &&
                loginForm.controls.passwordControl.dirty &&
                loginForm.controls.passwordControl.touched
              "
              >{{ validationMessages.passwordValidationMessage }}</mat-error
            >
            <svg-icon name="hide-icon" class="input-icon password-icon" (click)="hideViewPassword()"></svg-icon>
          </div>
          <div
            class="remember-group"
            [ngClass]="{
              'password-validation':
                loginForm.controls.passwordControl.hasError('pattern') &&
                loginForm.controls.passwordControl.dirty &&
                loginForm.controls.passwordControl.touched
            }"
          >
            <div class="form-checkbox form-checkbox--with-label">
              <input
                type="checkbox"
                [(ngModel)]="isRememberMeChecked"
                id="rememberme"
                formControlName="rememberMe"
                class="input-checkbox"
              />
              <label for="rememberme" class="form-checkbox-lbl"> Stay Logged in </label>
            </div>
            <div class="forgot-link">
              <a (click)="RouteForgotPassword()">Forgot password?</a>
            </div>
          </div>
          <button (click)="checkUser()" [disabled]="isLoggingIn" class="btn btn-lg" type="submit">Sign In</button>
        </form>

        <ng-container>
          <app-single-sign-on></app-single-sign-on>
        </ng-container>
      </div>
    </div>
  </div>
</div>
