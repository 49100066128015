<div class="single-sign-in-options">
  <hr class="separator" />
  <div>
    <button type="button" class="sso-microsoft btn btn-lg" aria-label="Sign in using Microsoft" (click)="login()">
      <svg-icon name="hjs-logo-white" class="icon"></svg-icon>
      <span class="sso-btn-label">Log in with SSO</span>
    </button>
  </div>
</div>

<div class="loader-animation" *ngIf="isDataLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
