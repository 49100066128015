import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefaultGlobalConfig, GlobalConfig, ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/shared/constants/constants';
import { ResponseCodes } from 'src/app/shared/enums/enums';
import { ValidationMessages } from 'src/app/shared/enums/messages';
import { LoginResponse } from 'src/app/shared/interfaces/Auth/LoginResponse';
import { AuthService, LoginService } from '../../../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  hide: boolean = true;
  isRememberMeChecked = true;
  isLoggingIn: boolean = false;

  validationMessages = ValidationMessages;

  loginForm: UntypedFormGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _authService: AuthService,
    private _toastr: ToastrService,
  ) {
    const currentUser = this._authService.get('encryptedValue');
    const isClient = this._authService.get('isClient');
    if (isClient != '' && isClient === 'true') {
      this._router.navigate(['client'], { replaceUrl: true });
    } else if (currentUser) {
      this._router.navigate(['admin'], { replaceUrl: true });
    } else {
      localStorage.clear();
    }

    this.initializeLoginForm();
  }

  initializeLoginForm() {
    this.loginForm = new UntypedFormGroup({
      emailControl: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
      ]),
      passwordControl: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(Config.validPasswordRegexAcceptingSpecialChars),
      ]),
      rememberMe: new UntypedFormControl('true'),
    });
  }

  //check loginform validation
  checkUser() {
    this.isLoggingIn = true;
    if (this.loginForm.valid) {
      this._loginService
        .login(
          this.loginForm.get('emailControl')?.value,
          this._loginService.encryptPassword(this.loginForm.get('passwordControl')?.value),
        )
        .subscribe({
          next: this.handleLoginSuccess.bind(this),
          error: this.handleLoginFailure.bind(this),
        });
    } else {
      this.isLoggingIn = false;
      this.manageFormValidation();
    }
  }

  handleLoginSuccess(result: LoginResponse) {
    this.isLoggingIn = false;
    localStorage.setItem('isClient', 'false');
    localStorage.setItem(
      'isRM',
      this.loginForm.get('rememberMe')?.value || this.loginForm.get('rememberMe')?.value === 'true' ? 'true' : 'false',
    );
    if (result.statusCode === ResponseCodes.Success) {
      this._router.navigate(['verify'], {
        state: {
          isUserLoggingIn: true,
          isNewUser: result.isNewUser,
          isNewPwdSet: result.isNewPwdSet,
          userId: result.userId,
          isAdmin: true,
          emailId: this.loginForm.controls.emailControl.value,
        },
        replaceUrl: false,
        skipLocationChange: true,
      });
    }
  }

  handleLoginFailure(error: HttpErrorResponse) {
    this.isLoggingIn = false;
    const globalConfig: GlobalConfig = DefaultGlobalConfig;
    this._toastr.toastrConfig = { ...globalConfig, preventDuplicates: true };
    this._toastr.error(error.error.message);
  }

  manageFormValidation() {
    if (this.loginForm.get('emailControl')?.hasError('required')) {
      this.markFormControlDirty('emailControl');
      this.loginForm.controls.emailControl.setValue('');
    } else if (this.loginForm.get('emailControl')?.hasError('pattern')) {
      this.markFormControlDirty('emailControl');
    } else if (this.loginForm.get('passwordControl')?.hasError('required')) {
      this.markFormControlDirty('passwordControl');
      this.loginForm.controls.passwordControl.setValue('');
    } else if (this.loginForm.get('passwordControl')?.hasError('pattern')) {
      this.markFormControlDirty('passwordControl');
    }
  }

  markFormControlDirty(formControl: string) {
    this.loginForm.get(formControl)?.markAsTouched();
    this.loginForm.get(formControl)?.markAsDirty();
  }

  checkPasswordLength(event: KeyboardEvent) {
    if (this.loginForm.controls.passwordControl.value.length >= 16) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  RouteForgotPassword() {
    this._router.navigate(['forgotpassword'], {});
  }

  hideViewPassword() {
    this.hide = !this.hide;
  }
}
