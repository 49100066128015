import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/shared/services';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent {
  @ViewChild('emailConfirmed', { static: false }) emailConfirmed: any;
  @ViewChild('linkExpired', { static: false }) linkExpired: any;
  userId: number;
  token: any;
  email: any;
  isScreenLoading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _loginService: LoginService,
    private modalService: NgbModal,
  ) {
    const temp: string | null = this.activatedRoute.snapshot.queryParamMap.get('userId');
    if (temp) this.userId = parseInt(temp);
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (!this.userId || !this.email || !this.token) {
      this.isScreenLoading = false;
      this.modalService.dismissAll();
      this.router.navigate(['login'], { replaceUrl: true });
    } else {
      this._loginService.confirmEmail(this.userId, this.email, this.token).subscribe({
        next: (res) => {
          this.isScreenLoading = false;
          this.openPopup(res.statusCode === 400 ? this.linkExpired : this.emailConfirmed);
        },
        error: () => {
          this.modalService.dismissAll();
          this.router.navigate(['login'], { replaceUrl: true });
        },
      });
    }
  }

  openPopup(popupRef: any) {
    this.modalService.open(popupRef, { windowClass: 'email-confirmed-modal', centered: true }).result.finally(() => {
      this.modalService.dismissAll();
      this.router.navigate(['login'], { replaceUrl: true });
    });
  }

  openLogin() {
    this.modalService.dismissAll();
    this.router.navigate(['login'], { replaceUrl: true });
  }
}
