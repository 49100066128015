<div class="auth-wrap">
  <div class="auth-wrap-left">
    <div class="logo">
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="auth-wrap-left-content">
      <h1>Let’s get going!</h1>
      <img src="../../assets/images/login-set.png" alt="" />
    </div>
  </div>
  <div class="auth-wrap-right">
    <div class="auth-wrap-right-content">
      <h1 class="margin-0">Set New Password</h1>
      <div class="auth-form">
        <form [formGroup]="resetPasswordform">
          <div
            class="form-field"
            [ngClass]="{ 'password-validation': resetPasswordform.controls.passwordControl.hasError('pattern') }"
          >
            <label for="password">New Password</label>
            <input
              id="password"
              matInput
              type="password"
              formControlName="passwordControl"
              placeholder="Enter Password"
              #password
              [type]="hide ? 'password' : 'text'"
              (keydown.space)="$event.preventDefault()"
              (keypress)="checkPasswordLength($event, 'passwordControl')"
            />
            <mat-error
              *ngIf="
                resetPasswordform.controls.passwordControl.hasError('required') &&
                resetPasswordform.controls.passwordControl.touched
              "
            >
              Password can not be blank
            </mat-error>
            <mat-error *ngIf="resetPasswordform.controls.passwordControl.hasError('pattern')">{{
              validationMessages.passwordValidationMessage
            }}</mat-error>
            <svg-icon name="hide-icon" class="input-icon password-icon" (click)="hideViewPassword(false)"></svg-icon>
          </div>
          <div class="form-field">
            <label for="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              matInpur
              type="password"
              formControlName="confirmPasswordControl"
              placeholder="Confirm Password"
              #confirmPassword
              [type]="hideConfirm ? 'password' : 'text'"
              (keydown.space)="$event.preventDefault()"
              (keypress)="checkPasswordLength($event, 'confirmPasswordControl')"
            />

            <mat-error
              *ngIf="
                !resetPasswordform.valid &&
                resetPasswordform.controls.confirmPasswordControl.dirty &&
                resetPasswordform.controls.confirmPasswordControl.touched
              "
            >
              Confirmed password does not match with the new password
            </mat-error>
            <svg-icon name="hide-icon" class="input-icon password-icon" (click)="hideViewPassword(true)"></svg-icon>
          </div>
          <button (click)="resetPassword()" [disabled]="isPasswordReset" class="btn btn-lg">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</div>
