import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import * as crypto from 'crypto-js';
import { AuthService } from 'src/app/shared/services';
import { SingleSignOnService } from 'src/app/shared/services/single-sign-on.service';

export const adminAuthGuard: CanActivateFn = () => {
  const _authService = inject(AuthService);
  const _ssoService = inject(SingleSignOnService);

  const token = _authService.get('accessToken');
  const data = _authService.get('encryptedValue');
  const isClient = _authService.get('isClient') === 'true';

  if (!token) {
    _authService.logoutAndRedirectToLogin();
    return false;
  } else if (isClient) {
    _authService.navigateTo(['client']);
    return false;
  }

  if (data) {
    const state = JSON.parse(crypto.AES.decrypt(data, token).toString(crypto.enc.Utf8));
    if (!state?.isUserLoggingIn) {
      _authService.logoutAndRedirectToLogin();
      return false;
    } else if (!_ssoService.isUserLoggedIn() && state.isNewUser) {
      navigateBasedOnState(_authService, state);
      return false;
    }
    return true;
  }
  _authService.logoutAndRedirectToLogin();
  return false;
};

const navigateBasedOnState = (_authService: AuthService, state: any) => {
  if (state.isNewPwdSet) {
    _authService.navigateTo(['profile'], state);
  } else {
    _authService.navigateTo(['resetPassword'], state);
  }
};
